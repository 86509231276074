import React from "react"
import styled from "styled-components"
import { applyPatch } from "mobx-state-tree"
import { observer } from "mobx-react"

export default observer((props) => (
  <Layout>
    <MultilineText
      ref={(element) => autoResizeHeight(element)}
      {...props}
    />
    {!props.isValidated && 
      <Validation>{props.validateMessage}</Validation>
    }
    { props.character_limit &&
      <CharacterCount>
        {props.item[props.leaf].length} / {props.character_limit}
      </CharacterCount>
    }
  </Layout>
))

const Validation = styled.small`
  color: #ff0000;
  position: absolute;
  font-size: .875rem;
  top: 3.75rem;
  left: .6875rem;
  text-align: left;
  white-space: nowrap;
  overflow: visible;
`

const CharacterCount = styled.span`
  align-self: flex-end;
  color: #BFBFBF;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  display: none;
`

const MultilineText = styled.input.attrs(({
  item,
  leaf,
  character_limit,
  ...rest,
}) => ({
  type: "text",
  ref: (element) => autoResizeHeight(element),
  onKeyUp: (e) => {
    if(rest.onSubmit && e.keyCode === 13)
      rest.onSubmit(e)
    return true;
  },
  onChange: ({ target }) => {
    applyPatch(item, {
      op: "replace",
      path: `/${leaf}`,
      value: (
        character_limit
        ? target.value.substr(0, character_limit)
        : target.value
      ),
    })
    autoResizeHeight(target)
  },
  ...rest,
}))`
  background: none;
  border: none;
  flex: 1 0 auto;
  outline: none;
  padding: 0.5rem 0;

  resize: none;
  height: 1rem;
  font-family: Infra;

  &:focus + ${CharacterCount} {
    display: inline-block;
  }
`

const autoResizeHeight = (target) => null /*{
  if(!target) return false
  let style = window.getComputedStyle(target)

  // Necessary for `target.scrollHeight` to give a meaningful result.
  target.style.height = "1rem"

  target.style.height =
    target.scrollHeight
    - parseInt(style["padding-top"], 10)
    - parseInt(style["padding-bottom"], 10)
    + "px"
}*/

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`

import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import { MOBILE_BREAKPOINT } from '../../../index'

const Layout = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 3rem;
  align-items: center;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 1.5rem 0;
  }
`

const Link = styled.a.attrs(({ to }) => ({
  href: to,
  target: "_blank",
}))`
  font-weight: 600;
  text-decoration: none;
  color: #1f1f1f;
  opacity: 0.5;
  font-size: .75rem;
  width: 10rem;
  text-align: center;

  &:hover {
    color: #000000;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    color: #bfbfbf;
  }
`

export default observer(({ self }) => (
  <Layout>
    <Link to="https://bettylabs.io/privacy-policy.pdf" >Privacy Policy</Link>
    <Link to="https://bettylabs.io/terms-of-use.pdf" >Terms of Service</Link>
    <Link to="https://sportsdata.io/" target="_blank">
      <img src="https://sportsdata.io/assets/images/badges/sportsdataio_dark_100.png?v=1" alt="Sports Data Provider" />
    </Link>
  </Layout>
))

import styled from "styled-components"

const Close = styled.div`
float: right;
cursor: pointer;
color: #242729;
font-weight: 600;
`

export default Close

// https://www.npmjs.com/package/react-qr-reader
// https://www.npmjs.com/package/qrcode.react

import "./fonts/Infra-Bold.otf"
import "./fonts/Infra-ExtraBold.otf"
import "./fonts/Infra-Medium.otf"
import "./fonts/Infra-Regular.otf"
import "./fonts/Infra-SemiBold.otf"

import './index.css';
import { activate } from "./helpers"

import Software from "./models/software"

import image1 from './screen1.png'
import image2 from './screen2.png'
import image3 from './screen3.png'
import image4 from './screen4.png'

export const MOBILE_BREAKPOINT = "37.5rem"

window.store = activate(Software, "root", {
  questions: [[
    "Aaron Donald is a cheat code at this point 💪 Will he sack Tom Brady again?",
    "Draymond can’t stop chatting up the refs 🗣 Will he get a technical foul?",
    "What will Deshaun Watson throw next: a touchdown, a pick, or an incompletion?",
    "Jayson Tatum hit the 3 like it was nothing 💦 Will he score again before halftime?",
  ], [
    "Kyrie is cooking right now! How will he score his next point?",
    "Dak Prescott is on another level 🤠 Who will he throw his next TD to?",
    "Embiid hit em with the Dikembe finger wag 💀 Will he block another shot?",
    "Fletcher Cox is a bad man! Will he sack Dak again tonight?",
  ], [
    "Zion Williamson is already that dude 👀 Will he dunk again before halftime?",
    "Saquon can’t be stopped! How many yards will his next catch gain?",
    "The Lakers are on a roll! How many 3s will they hit in the next 5 minutes?",
    "Khalil Mack might be a literal bear 🐻 Will he force another fumble?",
  ], [
    "Harden’s stepback 3 is activated 👀 How many more 3s will he hit?",
    "Tom Brady is 🐐 status Who will he throw his next pass to?",
    "Kawhi and Paul George are 🔥 tonight. Who will score more in the 4Q?",
    "Pat Mahomes doesn’t regress! Will he throw a TD in the 3Q?",
  ]],
  images: [
    {
      src: image1,
      alt: "Example game; win $750 when the Rockets play the Warriors"
    }, {
      src: image2,
      alt: "Example game; win $750 when the Rockets play the Warriors"
    }, {
      src: image3,
      alt: "Example game; win $750 when the Rockets play the Warriors"
    }, {
      src: image4,
      alt: "Example game; win $750 when the Rockets play the Warriors"
    }
  ]
})

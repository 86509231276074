import styled, { keyframes } from "styled-components"

const scrollX = keyframes`
  from { transform: translateX(-15.625rem); }
  to { transform: translateX(calc(100vw + 15.625rem)); }
`

const scrollY = keyframes`
  0% { transform: translateY(2rem); }
  30% { transform: translateY(0rem); }
  70% { transform: translateY(4rem); }
  100% { transform: translateY(2rem); }
`

export default styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 4rem;
  height: 20rem;
  animation: ${scrollY} 8s -${p => ((p.offset || 0) % 4)}s ease-in-out infinite;
  z-index: 10;

  & > * { position: absolute; }

  & > :nth-child(4n + ${p => ((p.offset || 0) + 1) % 4}) {
    top: 4rem;
    animation: ${scrollX} 16s -4s linear infinite;
  }

  & > :nth-child(4n + ${p => ((p.offset || 0) + 2) % 4}) {
    animation: ${scrollX} 16s -12s linear infinite;
  }

  & > :nth-child(4n + ${p => ((p.offset || 0) + 3) % 4}) {
    animation: ${scrollX} 16s -8s linear infinite;
  }

  & > :nth-child(4n + ${p => ((p.offset || 0) + 4) % 4}) {
    bottom: 4rem;
    animation: ${scrollX} 16s -16s linear infinite;
  }
`

import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"

export default observer(({ size }) => (
  <Logo
    height={size}
    width={size}
    viewBox="20 20 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M74.4441 50.0002C74.4441 63.5005 63.5 74.4446 49.9997 74.4446C36.4994 74.4446 25.5552 63.5005 25.5552 50.0002C25.5552 36.4999 36.4994 25.5558 49.9997 25.5558C63.5 25.5558 74.4441 36.4999 74.4441 50.0002ZM32.8886 50.0002C32.8886 59.4504 40.5495 67.1113 49.9997 67.1113C59.4499 67.1113 67.1108 59.4504 67.1108 50.0002C67.1108 40.55 59.4499 32.8891 49.9997 32.8891C40.5495 32.8891 32.8886 40.55 32.8886 50.0002Z" fill="#3ED8A6"/>
    <path d="M49.9997 29.2224C49.9997 27.1974 51.6504 25.5276 53.6527 25.8302C56.8721 26.3168 59.9747 27.4438 62.7719 29.1579C66.6156 31.5134 69.7332 34.8859 71.7798 38.9027C73.8265 42.9194 74.7225 47.4239 74.3688 51.9181C74.0151 56.4123 72.4254 60.7212 69.7756 64.3683C67.1258 68.0154 63.5191 70.8587 59.3542 72.5839C55.1892 74.3091 50.6283 74.8489 46.1757 74.1437C41.7231 73.4385 37.5523 71.5157 34.1243 68.5879C31.6297 66.4573 29.5992 63.8549 28.1415 60.9433C27.2349 59.1326 28.3129 57.0467 30.2388 56.4209C32.1648 55.7951 34.2016 56.8787 35.2227 58.6275C36.1854 60.2765 37.4218 61.7603 38.8869 63.0116C41.2865 65.061 44.2061 66.407 47.3229 66.9006C50.4397 67.3943 53.6323 67.0164 56.5478 65.8088C59.4633 64.6012 61.988 62.6109 63.8428 60.0579C65.6977 57.5049 66.8104 54.4887 67.058 51.3427C67.3056 48.1968 66.6784 45.0436 65.2458 42.2319C63.8131 39.4202 61.6308 37.0594 58.9402 35.4106C57.2974 34.4039 55.5042 33.6865 53.6384 33.2805C51.6597 32.8498 49.9997 31.2475 49.9997 29.2224Z" fill="#1F1F1F"/>
  </Logo>
))

const Logo = styled.svg`
  background-color: #ffffff;
  border-radius: 50%;
`

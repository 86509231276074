import React, { useState, useRef } from "react"
import { observer } from "mobx-react"
import styled from "styled-components"
import { Text } from "../../../elements"
import validator from "validator"

import { MOBILE_BREAKPOINT } from "../../../index"

const Action = styled.button`
  align-items: end;
  background-color: #1f1f1f;
  border-radius: 12px;
  border: none;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  padding: 0 1.25rem;
  text-align: center;
  white-space: nowrap;
  width: 8rem;
  cursor: pointer;
  height: 3rem;
  line-height: 3rem;

  &:hover {
    color: #51e9b8;
  }
`

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  max-width: 30rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  align-items: center;
  max-height: 4rem;
  justify-content: center;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  input {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 0 1.25rem;
    margin-right: 0.75rem;
    overflow: hidden;
    font-family: Infra;
    height: 3rem;
    font-size: .9375rem;

    &::placeholder {
      color: #BFBFBF;
    }
  }
`

const FormWrapper = styled.div`
  display: none;
`

export default observer(({ self }) => {
  const [ emailSubmitted, setEmailSubmitted ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ validated, setValidated ] = useState(true)

  const submitBtn = useRef(null);

  const onChangeEmail = (e) => {
    setEmail(e.target.value)
    if (validator.isEmail(e.target.value)) {
      setValidated(true)
    }
  }

  const onKeyPress = (event) => {
    if (event.keyCode === 13) {
      return onSubmitEmail();
    }
  }

  const onSubmitEmail = () => {
    if (validator.isEmail(email)){
      setValidated(true)
      submitBtn.current.click();
    } else {
      setValidated(false)
    }
  }

  const onFormSubmitted = async (event) => {
    event.preventDefault();

    await fetch(`https://bettylabs.us19.list-manage.com/subscribe/post-json?u=df68a6d4bf09a7f2c8db08d0d&id=c27c9aad55&c=callback`, {
      method: "POST",
      mode: "no-cors",
      body: new FormData(event.target)
    }) // TODO: can't seem to get data out of callback but this is working

    setEmailSubmitted(true)
  }

  return (emailSubmitted
    ? <Layout>Thanks! We'll notify you when we're live</Layout>
    : <Layout>
      <FormWrapper>
        <form action="https://bettylabs.us19.list-manage.com/subscribe/post?u=df68a6d4bf09a7f2c8db08d0d&amp;id=c27c9aad55" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate onSubmit={onFormSubmitted}>
          <div id="mc_embed_signup_scroll">
            <div className="mc-field-group">
              <input type="email" value={email} readOnly name="EMAIL" id="mce-EMAIL" />
            </div>
            <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
              <input type="text" name="b_df68a6d4bf09a7f2c8db08d0d_c27c9aad55" tabIndex="-1" value="" readOnly />
            </div>
            <div className="clear">
              <input ref={submitBtn} type="submit" value="Subscribe" readOnly name="subscribe" id="mc-embedded-subscribe" className="button" />
            </div>
          </div>
        </form>
      </FormWrapper>
      <Text 
        item={self} leaf="email" value={email} 
        placeholder="Enter your email" 
        onChange={onChangeEmail} 
        validateMessage={'Please type valid email address.'}
        isValidated={validated}
        type="email"
        onKeyDown={onKeyPress}
      />

      <Action
        onClick={onSubmitEmail}
      >
        Notify me!
      </Action>
    </Layout>
  )
})

import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { asReduxStore, connectReduxDevtools } from "mst-middlewares"
import { Observer } from "mobx-react"
import { onSnapshot } from "mobx-state-tree"

// Use as:
// activate(MobxStateTreeStore, "root", {...})
export const activate = (model, elementID, initialState = {}) => {
  let store = model.create(initialState)

  connectReduxDevtools(require("remotedev"), store)

  ReactDOM.render(
    <Provider store={asReduxStore(store)}>
      <Observer>
        {() => {
          try {
            return store.render
          }
          catch(e) {
            localStorage.setItem("storage", "")
            return <div>Error. Refresh to try with blank state.</div>
          }
        }}
      </Observer>
    </Provider>,
    document.getElementById(elementID),
  );

  onSnapshot(store, snapshot => {
    localStorage.setItem("storage", JSON.stringify(snapshot))
  })

  return store
}

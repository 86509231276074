import styled from "styled-components"

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 2rem;
  padding: 1rem 0;

  & > *:last-child {
    margin-left: auto;
  }
`

export default Footer

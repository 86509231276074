import styled from "styled-components"

const Option = styled.label`
margin-top: 1rem;
display: inline-block;
cursor: pointer;
position: relative;
display: flex;

&:before {
  content: "";

  display: inline-block;
  width: 1rem;
  height: 1rem;
  position: absolute;

  border: 2px solid ${({ checked }) => checked ? "#51e9b8" : "#888888"};
  border-radius: 50%;
}

&:after {
  content: "";
  height: 0.8rem;
  width: 0.8rem;
  position: absolute;
  left: calc(0.2rem + 0.5px);
  top: calc(0.2rem + 0.5px);
  border-radius: 50%;
  background-color: #51e9b8;
  opacity: ${({ checked }) => checked ? 1 : 0 };
}
`

export default Option

import React from "react"
import { applyPatch } from "mobx-state-tree"
import { DateTime } from "luxon"

let templates = {}

export const initialize_templates = (self) => () => {
  let model_name = self.$treenode.type.name

  let template_names =
    require
    .context(`./models/`, true, /\.js$/)
    .keys()
    .map(file => {
      let module = new RegExp(`^./${model_name}/(.+).js$`).exec(file)
      return module && module[1]
    })
    .filter(file => file !== `index`)
    .filter(module => module !== null)

  template_names.forEach(template_name => {
    load_template(model_name, template_name)

    Object.defineProperty(self, template_name, {
      get: () => template(self, template_name),
    })
  })
}

const load_template = (model_name, template_name) => {
  if(!templates[model_name]) templates[model_name] = {}

  import(`./models/${model_name}/${template_name}`)
    .then(module => {
      templates[model_name][template_name] = module.default
      applyPatch(window.store, {
        op: "replace",
        path: `/_templates/${model_name}/${template_name}`,
        value: DateTime.local(),
      })
    })
}

const template = (self, template) => {
  let available = false
  let model_name = self.$treenode.type.name

  try {
    let timestamp = self.$treenode.root.value._templates[model_name][template]

    available = (
      timestamp &&
      !timestamp.invalid &&
      templates[model_name][template]
    )
  } catch(e) {
    available = false
  }

  return available
  ? React.createElement(
      templates[model_name][template],
      { self, key: `${self.$treenode.path}:${template}` },
    )
  : null
}

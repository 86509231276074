import styled from "styled-components"
import { applyPatch } from "mobx-state-tree"

const Checkbox = styled.input.attrs(({ item, leaf, ...rest }) => ({
  type: "checkbox",
  onChange: () => applyPatch(item, {
    op: "replace",
    path: `/${leaf}`,
    value: !item[leaf],
  }),
  ...rest,
}))``

export default Checkbox

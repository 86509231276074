import React from "react"
import { observer } from "mobx-react"
import styled from "styled-components"
import APP_STORE_DOWNLOAD_IMAGE_BLACK_URL from "../../../Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
// import APP_STORE_DOWNLOAD_IMAGE_WHITE_URL from "../../../Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg"

const Link = styled.a.attrs(({ to }) => ({
  href: to,
  target: "_blank",
}))`
  align-items: end;
  border: none;
  display: flex;
  justify-content: space-around;
  margin: 2rem auto;
  padding: 1rem;
`

const AppStoreImage = styled.img`
  height: 3.5rem;
`

export default observer(({ to }) => (
  <Link to={to} >
    <AppStoreImage src={APP_STORE_DOWNLOAD_IMAGE_BLACK_URL} alt="Download on the App Store" />
    {/* <AppStoreImage src={APP_STORE_DOWNLOAD_IMAGE_WHITE_URL} alt="Download on the App Store" /> */}
  </Link>
))

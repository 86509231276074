import styled from "styled-components"
import { applyPatch } from "mobx-state-tree"

const Number = styled.input.attrs(({ item, leaf, ...rest }) => ({
  type: "number",
  onChange: ({ target }) => applyPatch(item, {
    op: "replace",
    path: `/${leaf}`,
    value: parseInt(target.value, 10),
  }),
  ...rest,
}))`
background: none;
border: none;
border-bottom: 1px solid rgba(0, 0, 0, 0.12);
&:focus { border-bottom: 2px solid #51e9b8; }
`

export default Number

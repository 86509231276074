/* eslint-disable jsx-a11y/accessible-emoji */

import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"

import logo from "../../logo.svg"
import logo_light_bg from "../../logo_light_bg.svg"

import { Card, Phone } from "../../elements"

import Icon from "@mdi/react"

import { MOBILE_BREAKPOINT } from "../../index"

import {
  ApplicationLink,
  Carousel,
  // EmailCollection,
  Footer,
} from "./layers"

const REACT_APP_APPSTORE_URL = "itms-apps://apps.apple.com/us/app/sideline-sports/id1451654308"

const QuestionIcon = styled(Icon)`
  background-color: #51cee9;
  border-radius: 50%;
  border: 6px solid #abe2ee;
  overflow: visible;
  padding: 0.4rem;

  position: absolute;
  top: -1.5rem;
  left: calc(50% - 1.5rem);

  display: block;
  height: 4rem;
  width: 4rem;

  path {
    transform: translate(0.125rem, 0.125rem);
  }
`

const display = questions =>
  questions.map((question, index) => (
    <QuestionCard key={index}>
      <QuestionIcon size={1} color="#ffffff" path="M9.04894 0.927052C9.3483 0.00574108 10.6517 0.00573985 10.9511 0.92705L12.4697 5.60081C12.6035 6.01284 12.9875 6.2918 13.4207 6.2918H18.335C19.3037 6.2918 19.7065 7.53141 18.9228 8.10081L14.947 10.9894C14.5966 11.244 14.4499 11.6954 14.5838 12.1074L16.1024 16.7812C16.4017 17.7025 15.3472 18.4686 14.5635 17.8992L10.5878 15.0106C10.2373 14.756 9.7627 14.756 9.41221 15.0106L5.43648 17.8992C4.65276 18.4686 3.59828 17.7025 3.89763 16.7812L5.41623 12.1074C5.5501 11.6954 5.40345 11.244 5.05296 10.9894L1.07722 8.10081C0.293507 7.53141 0.696283 6.2918 1.66501 6.2918H6.57929C7.01252 6.2918 7.39647 6.01284 7.53035 5.60081L9.04894 0.927052Z" />
      {question}
    </QuestionCard>
  ))

const banner_content = (self) => [
  <Heading key="heading">
    <Nowrap>The game about</Nowrap>
    {' '}
    <Nowrap>the game.</Nowrap>
  </Heading>,
  <Tag key="tag">Watch. Play. Win.</Tag>,
  <ApplicationLink key="application-link" to={REACT_APP_APPSTORE_URL} />,
]

const Spacer = styled.div`
  flex: 1;
`

export default observer(({ self }) => {
  return (
    <WidthConstraint sizes={[MOBILE_BREAKPOINT]}>
      <Layout>
        <Banner>
          <BackgroundlessLogo/>
          {banner_content(self)}
        </Banner>

        <QuestionList>
          <div className="question-list__spacer" />
          {display(self.questions[self.randomQuestionGroupIndex])}
          <div className="question-list__spacer" />
        </QuestionList>
        <Phone image={self.images[self.randomImageIndex]} />
        <Spacer />
        <Footer/>
      </Layout>

      <LargeLayout>
        <PaletteLogo/>

        <Circus>
          <Carousel offset={3}>
            <Emoji key=":crossed_fingers:">🤞</Emoji>
            <Emoji key=":raised_hands:">🙌</Emoji>
            <Emoji key=":clapping_hands:">👏</Emoji>
            <Emoji key=":money_face:">🤑</Emoji>
          </Carousel>

          <Carousel>
            {display(self.questions[self.randomQuestionGroupIndex])}
          </Carousel>

          <LargePhone>
            <Phone image={self.images[self.randomImageIndex]} />
          </LargePhone>
        </Circus>

        <Banner>
          <Spacer />
          {banner_content(self)}
          <Spacer />
          <Footer />
        </Banner>

      </LargeLayout>
    </WidthConstraint>
  )
})

const LargePhone = styled.div`
  position: absolute;
  width: 32vh;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 14.3vh);
`

const Circus = styled.div`
  position: relative;
  z-index: 1;
  height: 50vh;
`

const QuestionList = styled.div`
  position: relative;
  display: inline-grid;
  grid-column-gap: 1.25rem;
  grid-template-columns: 0 repeat(4, auto) 0;
  overflow-x: scroll;
  padding-top: 4rem;
  padding-bottom: 2rem;

  & > .question-list__spacer {
    width: 1px;
  }
`

const Emoji = styled(Card)`
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
  font-size: 2rem;
  margin-left: 6.8125rem;
  padding: 0;
  justify-content: center;
  align-items: center;
`

const QuestionCard = styled(Card)`
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.08);
  font-weight: 600;
  overflow: visible;
  padding-top: 2rem;
  position: relative;
  text-align: center;
  width: 15.625rem;
  font-size: 1.125rem;
`

const WidthConstraint = observer(({ sizes, children }) => {
  let mediaQueryDivs = [
    styled.div`
      display: block;
      @media (min-width: ${sizes[0]}) { display: none; }
    `,
    styled.div`
      display: block;
      @media (max-width: ${sizes[0]}) { display: none; }
    `,
  ]

  return mediaQueryDivs.map((element, index) =>
    React.createElement(element, {}, children[index])
  )
})

const BackgroundlessLogo = styled.img.attrs({
  src: logo,
  alt: "Sideline Logo",
})`
  margin-top: 2rem;
`

const PaletteLogo = styled.img.attrs({
  src: logo_light_bg,
  alt: "Sideline Logo",
})`
  max-height: 48px;
  left: 32px;
  position: absolute;
  top: 32px;
  width: auto !important;
  align-self: flex-start;
`

const Banner = styled.div`
  background-color: #51e9b8;
  box-sizing: border-box;
  color: #1f1f1f;
  padding: 0 2rem;
  text-align: center;
  z-index: 0;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 0;
    padding-bottom: 2.5rem;
  }
`

const Layout = styled.div`
  align-items: center;
  background-color: #F6F6F6;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  width: 100vw;
  min-height: 100vh;

  & > * {
    width: 100vw;
  }
`

const LargeLayout = styled(Layout)`
  min-height: 100vh;
  overflow: hidden;

  ${Banner} {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 50vh;
  }
`

const Heading = styled.h1`
  margin-bottom: 1rem;
  padding-top: 4rem;
  font-size: 3rem;
  font-weight: 800;
  line-height: 3.5rem;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 2.5rem;
  }
`

const Tag = styled.div`
  opacity: 0.5;
  color: #1F1F1F;
  font-size: 1.5rem;
  font-weight: 600;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 1.25rem;
  }
`

const Nowrap = styled.span`
  white-space: nowrap;
`

import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import { MOBILE_BREAKPOINT } from '../index'

import IMAGE_PHONE from '../iphonex.png'

export default observer((props) => (
  <Layout>
    <PhoneImage src={IMAGE_PHONE} alt={props.image.get('alt')} />
    <LayoutInner>
      <PhonePadding>
        <GameImage src={props.image.get('src')} alt={props.image.get('alt')} />
      </PhonePadding>
    </LayoutInner>
  </Layout>
))

const GameImage = styled.img`
  width: 100%;
`

const PhoneImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

const Layout = styled.div`
  position: relative;
  max-width: 473px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    max-width: 80%;
  }

  &::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(933/499 * 100%);
  }
  &::after {
    content: "";
    display: table;
    clear: both;
  }
`

const LayoutInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const PhonePadding = styled.div`
  padding: 12%;
`

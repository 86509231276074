import styled from "styled-components"

const Card = styled.div`
  align-items: justify;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 4px lightgrey;
  color: #242729;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
`

Card.Header = styled.div`
  align-items: baseline;
  display: flex;
  height: auto;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-bottom: 1rem;
  overflow: hidden;
`

export default Card

import styled from "styled-components"

const Button = styled.button`
  background-color: ${({ disabled }) => disabled ? "#e6ecef" : "#51e9b8"};
  border-radius: 8px;
  border: none;
  color: #333333;
  float: right;
  font-weight: 600;
  padding: 0.5rem;
`

export default Button

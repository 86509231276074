import { types } from "mobx-state-tree"
import { initialize_templates } from "../../dynamic_templates"
import Time from "../time"

// Queries
import ApolloClient from "apollo-client";
import gql from "graphql-tag"
import { InMemoryCache } from "apollo-cache-inmemory"
import { createHttpLink } from "apollo-link-http"
import { setContext } from "apollo-link-context"

const httpLink = createHttpLink({ uri: `http://${process.env.REACT_APP_URL_HASURA}` })
const authLink = setContext((_, { headers }) => (
  { headers: {
    ...headers,
    "x-hasura-access-key": process.env.REACT_APP_HASURA_SECRET,
  } }
))
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export default types.model("software", {
  questions: types.optional(types.array(types.array(types.string)), []),
  images: types.optional(types.array(types.map(types.string)), []),
  randomImageIndex: types.optional(types.number, Math.floor(Math.random() * 4)),
  randomQuestionGroupIndex: types.optional(types.number, Math.floor(Math.random() * 4)),
  email: "",

  _templates: types.optional(types.model({
    software: types.optional(types.model({
      render: types.maybe(Time),
      action: types.maybe(Time),

      layers: types.optional(types.model({
        application_link: types.maybe(Time),
        carousel: types.maybe(Time),
        email_collection: types.maybe(Time),
        footer: types.maybe(Time),
        index: types.maybe(Time),
      }), {}),
    }), {}),
  }), {}),
}).actions(self => ({
  afterCreate: initialize_templates(self),

  recordEmail(email) {
    client.mutate({ mutation: gql`
      mutation ($email_address: String!) {
        insert_emails(objects: {
          email_address: $email_address,
        }) { affected_rows }
      }
      `,
      variables: {
        email_address: email,
      },
    })
    self.email = ""
  },
}))
